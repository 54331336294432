<template>
  <div class="row h-100">
    <div
      class="col-sm-12 col-md-4 py-10 px-3"
      style="background-image: linear-gradient(to top, #090100, #c60001)"
    >
      <div class="row justify-content-center my-20">
        <img src="/img/aside/Logo-Baru-White.png" style="width: 350px" alt="" />
      </div>
      <div class="row justify-content-center my-20">
        <img src="/img/login/logo_ocbc.png" class="max-h-50px" alt="" />
      </div>
      <div class="row justify-content-center my-20">
        <img src="/img/login/logo_kgmedia.png" class="max-h-50px" alt="" />
      </div>
    </div>
    <div
      class="col-sm-12 col-md-8 pl-0"
      style="
        background-image: url('/img/login/bg-secondary.jpeg');
        background-size: cover;
        background-position: center bottom;
        background-repeat: no-repeat;
        position: relative;
      "
    >
      <div
        class="row px-10"
        :class="isMobile() ? 'justify-content-center' : 'justify-content-end'"
      >
        <span
          class="d-block text-center"
          :class="isMobile() ? 'text-white' : ''"
          style="font-size: 5rem; color: rgba(0, 0, 0, 0.6)"
          >{{ time }} <sub style="font-size: 2rem">WIB</sub>
        </span>
      </div>
      <div class="row no-gutters justify-content-center" style="margin-top: 15rem;">
        <span
          class="d-block text-center"
          style="color: rgba(0, 0, 0, 0.5); line-height: 0"
          :style="isMobile() ? 'font-size: 2.5rem;' : 'font-size: 4.5rem;'"
          >CMS OCBC - KGMEDIA</span
        >
      </div>
      <div class="row no-gutters justify-content-center">
        <span
          class="d-block text-center mt-2"
          style="color: rgba(0, 0, 0, 0.5)"
          :style="isMobile() ? 'font-size: 2.5rem;' : 'font-size: 4.5rem;'"
          >VIRTUAL EVENT</span
        >
      </div>
      <div
        class="row no-gutters justify-content-center"
        :class="isMobile() ? 'ml-8' : ''"
      >
        <div class="col-sm-3 col-md-5 col-lg-6">
          <!--begin::Signin-->
          <div class="card shadow-lg rounded-lg">
            <div class="card-body py-4" :class="isMobile() ? 'px-6' : ''">
              <form
                class="form"
                novalidate="novalidate"
                id="kt_login_signin_form"
                @submit.stop.prevent="onSubmitLogin()"
              >
                <div class="form-group">
                  <label class="font-size-h6 font-weight-bolder text-dark"
                    >Email</label
                  >
                  <div
                    id="example-input-group-1"
                    label=""
                    label-for="example-input-1"
                  >
                    <input
                      class="
                        form-control form-control-solid
                        h-auto
                        py-4
                        px-6
                        rounded-lg
                      "
                      type="text"
                      name="email"
                      ref="email"
                      v-model="form.email"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <div class="d-flex justify-content-between mt-n5">
                    <label
                      class="font-size-h6 font-weight-bolder text-dark pt-5"
                      >Password</label
                    >
                  </div>
                  <div
                    id="example-input-group-2"
                    label=""
                    label-for="example-input-2"
                  >
                    <div class="input-group mb-2">
                      <input
                        class="
                          form-control form-control-solid
                          h-auto
                          py-4
                          px-6
                          rounded-lg
                        "
                        :type="passwordIsVisible"
                        name="password"
                        ref="password"
                        v-model="form.password"
                        autocomplete="off"
                      />
                      <div
                        class="input-group-prepend"
                        @click="
                          passwordIsVisible == 'password'
                            ? (passwordIsVisible = 'text')
                            : (passwordIsVisible = 'password')
                        "
                      >
                        <div
                          class="input-group-text"
                          style="border: none; cursor: pointer"
                        >
                          <template v-if="passwordIsVisible == 'password'">
                            <b-icon-eye-slash></b-icon-eye-slash>
                          </template>
                          <template v-if="passwordIsVisible == 'text'">
                            <b-icon-eye></b-icon-eye>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pb-lg-0 pb-5">
                  <button
                    ref="kt_login_signin_submit"
                    class="
                      btn btn-primary
                      font-weight-bolder font-size-h6
                      px-8
                      py-3
                      my-3
                      mr-3
                      text-white
                      bg-primary
                    "
                  >
                    Sign In
                  </button>
                </div>
              </form>
            </div>
          </div>
          <!--end::Signin-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service.js";
import JwtService from "@/core/services/jwt.service";
import { LOGIN, LOGOUT, REGISTER } from "@/core/services/store/auth.module";

export default {
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      // other
      passwordIsVisible: "password",
      interval: null,
      time: null,
    };
  },
  methods: {
    isMobile() {
      var checkIsMobile = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          checkIsMobile = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return checkIsMobile;
    },
    onSubmitLogin() {
      SwalLoading.fire();

      var email = this.form.email;
      var password = this.form.password;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // dummy delay
      setTimeout(() => {
        // send login request
        this.$store
          .dispatch(LOGIN, { email, password })
          // go to which page after successfully login
          .then(() => {
            let access_right, role_right;
            ApiService.get("api/users/me").then((data) => {
              ApiService.get("api/users/" + data.data.data.id).then(
                (response) => {
                  if (data.data.data) {
                    ApiService.get("api/roles/" + data.data.data.role_id).then(
                      (result) => {
                        console.log("result", result);
                        access_right = response.data.meta.access_right_display;
                        role_right = result.data.meta.access_right_display;

                        let merge = [...access_right, ...role_right];

                        window.localStorage.setItem(
                          "access_right_display",
                          JSON.stringify(merge)
                        );

                        let user = data.data.data;
                        JwtService.setUser(JSON.stringify(user));

                        this.$router.push({ path: "/pitching-events" });
                        SwalLoading.close();
                      }
                    );
                  } else if (!data.data.data.position_id) {
                    window.localStorage.setItem(
                      "access_right",
                      response.data.meta.access_right
                    );

                    window.localStorage.setItem(
                      "access_right_display",
                      JSON.stringify(response.data.meta.access_right_display)
                    );

                    let user = data.data.data;
                    access_right = response.data.meta.access_right;
                    user.access_right = response.data.meta.access_right;
                    JwtService.setUser(JSON.stringify(user));

                    for (let z = 0; z < 20; z++) {
                      if (z == 19) {
                        this.$router.push({ path: "/pitching-events" });
                        SwalLoading.close();
                      }
                    }
                  }
                }
              );
            });
          });
      }, 100);
    },
  },
  created() {
    // update the time every second
    this.interval = setInterval(() => {
      // Concise way to format time according to system locale.
      this.time = Intl.DateTimeFormat(navigator.language, {
        hour: "numeric",
        minute: "numeric",
        hourCycle: "h23",
      }).format();
    }, 1000);
  },
};
</script>

<style>
  .margin-text-cms {
    margin-top: 15rem;
  }
</style>